.phong {
  padding-top: 72px;
  padding-bottom: 120px;
  width: 80%;
  max-width: 936px;
  margin: auto;
}

.phong .title {
  font-weight: 600;
  font-size: 34px;
  line-height: 42px;
  color: #140A52;
  text-align: center;
  margin-bottom: 24px;
}

.phong p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #260D28;
}

.phong p.headline {
  font-weight: 400;
  font-size: 21px;
  line-height: 33px;
  color: #B5058E;
}

.phong p img {
  float: left;
  margin: 0 20px 10px 0;
  content: url('./images/phong.svg');
}

.phong p .title {
  font-weight: 600;
  font-size: 27px;
  line-height: 34px;
  text-align: left;
  margin-bottom: 0px;
}

.phong-who-is {
  margin-top: 60px;
}

img.financial-lifecycle {
  content: url('./images/financial-lifecycle.svg');
}

/*----------------------------------------------*/

@media screen and (max-width: 480px) {
  .phong {
    padding-top: 40px;
  }

  img.financial-lifecycle {
    width: 347px;
    height: 266px;
    content: url('./images/financial-lifecycle-small.png');
  }

  .phong-who-is {
    padding-top: 240px;
  }

  .phong p .title {
    text-align: center;
  }

  .phong p img {
    float: none;
    width: 226px;
    height: 228px;
    margin-top: -250px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    content: url('./images/phong.png');
  }
}


/*----------------------------------------------*/