.family-financial-workshop {
    position: relative;
    padding: 30px 16px 30px 16px;
    overflow: hidden;
  }
  
  .family-financial-workshop .container {
    width: 100%;
    max-width: 934px;
    padding: 32px;
    background-color: #FDFDFD;
    border-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(20, 10, 82, 0.08);
    text-align: center;
  }

  .family-financial-workshop .container .small-title {
    width: 100%;
    text-align: left;
  }

  .family-financial-workshop .container .title {
    width: 100%;
    text-align: left;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    margin-bottom: 10px;
  }

  .family-financial-workshop .container .subtitle {
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 17px;
  }

  .family-financial-workshop .container .description-container {
    display: flex;
    gap: 20px;
  }

  .family-financial-workshop .container .description-container img {
    max-width: 300px;
  }

  .family-financial-workshop .container .description-container .description {
    text-align: left;
  }

  @media screen and (max-width: 700px) {
    .family-financial-workshop .container .description-container {
      display: block;
    }

    .family-financial-workshop .container .description-container .description {
      margin-top: 30px;
    }
  }