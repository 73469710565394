.signup {
  position: relative;
  padding: 30px 16px 30px 16px;
  overflow: hidden;
}

.signup .container {
  width: 100%;
  max-width: 934px;
  padding: 32px;
  background-color: #FDFDFD;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(20, 10, 82, 0.08);
  text-align: center;
}

.signup .container .title {
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  margin-top: -4px;
}  

.signup .container .details {
  width: 100%;
  max-width: 600px;
  justify-content: space-between;
  margin: auto;
}

.signup .container .details .detail {
  display: flex;
  color: #0D0350;
  gap: 8px
}

.signup .container form {
  padding: 30px 16px 30px 16px;
}

.signup .container form button {
  margin-top: 20px;
}

.signup .bg-top-right {
  position: absolute;
  top: -600px;
  right: 0px;
  z-index: -100;
}

.modal .container {
  width: 100%;
  max-width: 600px;
  padding: 32px;
  background-color: #FDFDFD;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(20, 10, 82, 0.08);
  text-align: center;
  margin: auto;
}

.modal .container .title {
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  margin-top: -4px;
  color: #140A52;
}

.modal .container > img {
  margin-top: 30px;
}

.modal .container .subtitle {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: #140A52;
}

.modal .container .description {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  width: 100%;
  max-width: 512px;
  margin: auto;
  color: #0D0350
}

.modal .container .details {
  /*background-color: red;*/
  margin: 20px 0 0px 0;
  border-top: 1px solid #E9E8E9;
  padding: 20px 0 0 0;
  justify-content: space-around;
}

.modal .container .details .detail {
  display: flex;
  gap: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #0D0350;
}

.modal .container .details .detail > img {
  width: 16px;
  height: auto;
}

.modal .container > button {
  margin-top: 30px;
}

@media screen and (max-width: 700px) {
  .signup .container .title {
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
  }  

  .signup .container {
    padding: 16px;
  }

  .signup .container .details {
    display: block;
  }

  .signup .container .details .detail {
    margin-top: 12px;
  }

  .signup .bg-top-right {
    display: none;
  }

  .modal .container {
    border-radius: 10px 10px 0 0;
  }

  .modal .container .title {
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
  }  

  .modal .container .details {
    display: none;
  }
}