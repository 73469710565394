.hero {
  position: relative;
  background-color: #140A52;
  clip-path: circle(8000px at 50% calc(100% - 8000px));
  padding: 80px 30px 80px 30px;
}

.hero .container {
  gap: 24px;
}

.hero .container .description {
  max-width: 679px;
}

.hero .container .description button {
  margin-top: 24px;
}

.hero .bg-top-left {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -100;
}

.hero .bg-bottom-right {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: -100;
}

@media screen and (max-width: 700px) {
  .hero {
    clip-path: circle(800px at 50% calc(100% - 800px));
    padding: 20px 46px 60px 46px;
  }

  .hero .container {
    text-align: center;
  }

  .hero .container > img {
    display: none;
  }

  .hero .container .description > h3 {
    display: none;
  }

  .hero .bg-top-left {
    display: none;
  }

  .hero .bg-bottom-right {
    display: none;
  }
}