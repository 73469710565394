.footer {
  background-color: #050928;
  margin-top: 60px;
  clip-path: circle(8000px at 50% 8000px);
  padding: 65px 97px 30px 97px;
}

.footer .container {
  width: 100%;
  max-width: 934px;
  margin: auto;
}

.footer .container .description {
  width: 343px;
  padding: 0 0 30px 0;
}

.footer .container .bottom .disclaimer {
  border-top: 1px solid rgba(252, 252, 252, .2);
  margin-top: 52px;
  padding-top: 10px;
}

@media screen and (max-width: 700px) {
  .footer {
    clip-path: circle(800px at 50% 800px);
    padding: 65px 16px 30px 16px;
  }

  .footer .container .description {
    width: 100%;
  }

  .footer .container .description > img {
    width: 150px;
    height: auto;
  }
}