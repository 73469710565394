.banner {
  background-color: #B5058E;
  width: 100%;
  height: 74px;
  /*position: sticky;
  top: 100px;
  z-index: 2000;*/
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px 0 80px;
}

.banner .description {
  position: relative;
  display: flex;
  gap: 12px;
  color: #FDFDFD;
}

.banner button {
  min-width: 104px;
  height: 42px;
  background-color: #140A52;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.56px;
  color: #FDFDFD;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .banner {
    display: none;
  }
}

