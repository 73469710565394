.header {
  height: 100px;
  width: 100%;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 20000;
  box-shadow: 0px 6px 8px rgba(20, 10, 82, 0.08);
  transition: all 0.1s linear; 
}

.logo {
  position: absolute;
  left: 158px;
  top: 26px;
}

.header button {
  position: absolute;
  top: 22px;
  right: 80px;
}

@media screen and (max-width: 700px) {
  .header {
    height: 57px;
  }

  .logo {
    position: absolute;
    left: 16px;
    top: 16px;
  }

  .logo img {
    width: 100px;
    height: auto;
  }

  .header button {
    position: absolute;
    top: 10px;
    right: 16px; 
    min-width: 104px;
    height: 34px;  
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 142.857% */
    letter-spacing: 0.56px;    
    padding: 0; 
  }
}

