.sponsor {
  padding: 30px 16px 30px 16px;
  background-color: #F6F6F6;
}

.sponsor .container {
  text-align: center;
}

@media screen and (max-width: 700px) {
  .sponsor .container > img {
    width: 186px;
    height: auto;
    margin-top: 20px;
  }

}