.home {
  position: relative;
}

.home .banner-container {
  position: sticky;
  top: 100px;
  z-index: 2000;
}

.home .signup-button {
  position: sticky;
  top: -76px;
  right: 80px;
  z-index: 25000;
}

@media screen and (max-width: 700px) {
  .home .banner-container {
    opacity: 0;
  }
}