.instructor {
  background-color: #DED7FF;
  padding: 80px 30px 80px 30px;
}

.instructor .container {
  gap: 24px;
}

.instructor .container .description {
  max-width: 648px;
}

.instructor .container .description .title {
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
}

.instructor .container .description button {
  margin-top: 24px;
}

@media screen and (max-width: 700px) {
  .instructor {
    padding: 40px 30px 40px 30px;
  }

  .instructor .container {
    display: block;
  }

  .instructor .container .img-container {
    width: 100%;
    text-align: center;
  }

  .instructor .container .img-container > img {
    width: 208px;
    height: auto;
    text-align: center;
  }

  .instructor .container .description .title {
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    margin-top: 20px;
  }

  .instructor .container .description button {
    width: 100%;
    margin-top: 10px;
  }
}