@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #260D28;
}

button {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 117.647% */
  letter-spacing: 0.68px;
  min-width: 181px;
  height: 54px;
  padding: 16px 20px 16px 24px;
  border-radius: 6px;
  color: #FDFDFD;
  border: none;
  background-color: #B5058E;
  cursor: pointer;
}

button.purple {
  background-color: #140A52;
}

button:disabled {
  opacity: 0.65;
  pointer-events: none;
}

button.processing {
  background-image: none;
}

.processing-button-container {
  position: relative;
  display: inline-block;
  /*background-color: red;*/
  /*width: 227px;
  height: 54px;*/
}

.processing-button-container > img {
  width: 24px;
  height: auto;   
  position: absolute;
  top: 30px;
  right: 20px;
}

.rotation {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotation.show {
  opacity: 1;
}

.rotation.hide {
  opacity: 0;
}


textarea, input[type=text], input[type=email], .phone-input {
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #0D0350;
  padding: 0px;
  margin: 0px;
  height: 40px;
  border: 1px solid #D5D4D5;
  border-radius: 6px;
  padding-left: 10px;
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box;     
  box-sizing: border-box;
  background-color: transparent;
  background: transparent;
}

input[type=text], input[type=email], .phone-input {
  height: 44px;
}

textarea {
  height: 80px;
  padding: 10px;
  resize: none;
}

input[type=text]:focus, input[type=email]:focus, .phone-input:focus, textarea:focus {
  outline: none !important;
  border: 1px solid #140A52 !important;
}

form {
  text-align: left;
}

form .field {
  margin-bottom: 20px;
  text-align: left;
}

form .field-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin: 0px 0px 4px 4px;
}

form .field-error {
  color:rgb(189, 53, 53);
  font-size: 10px;
  margin: 2px 0px 0px 4px;
}

form .field-error.submit {
  font-size: 14px;
  margin: 2px 0px 20px 4px;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.regular {
  font-weight: 400;
}

.semibold {
  font-weight: 600;
}

.color-neutral-white {
  color: #FDFDFD;
}

.color-pink {
  color: #B5058E;
}

.color-light-pink {
  color: #E58CD1;
}

.color-purple {
  color: #140A52;
}

.color-light-gray {
  color: #6E666F;
}

h1 {
  font-size: 34px;
  font-style: normal;
  line-height: 42px;
}

h2 {
  font-size: 27px;
  font-style: normal;
  line-height: 34px;
}

h3 {
  font-size: 22px;
  font-style: normal;
  line-height: 32px;
}

.display-semibold {
  font-family: Poppins;
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 66px;
}

.display-medium {
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
}

.display-small {
  font-size: 12px;
  font-style: normal;
  line-height: 18px;
}

.subheading {
  font-size: 18px;
  font-style: normal;
  line-height: 26px;
}

.link {
  text-decoration: none;
  color: #FEFDFD;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal {
  width: 100%;
  max-width: 934px;
  background: transparent;
  position: absolute;
  padding: 0 20px 0 20px;
  outline: none;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(13, 3, 80, 0.7);
  z-index: 2000000;
}

/*----------------------------------------------------------*/

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 800ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content {
  top: 250%;
  left: 50%;
  transform: translate(-50%, 0%);
  transition: 0.6s;
}

.ReactModal__Content--after-open{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ReactModal__Content--before-close{
  top: 250%;
  left: 50%;
  transform: translate(-50%, 0%);
}

/*----------------------------------------------*/

@media screen and (max-width: 700px) {
  .display-semibold {
    font-family: Poppins;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
  }

  h2 {
    font-size: 18px;
    font-style: normal;
    line-height: 26px;
  }

  .modal {
    padding: 0px;
  }

  .ReactModal__Content {
    top: 250%;
    left: 50%;
    transform: translate(-50%, 0%);
    transition: 0.6s;
  }

  .ReactModal__Content--after-open{
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
  }

  .ReactModal__Content--before-close{
    top: 250%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

}