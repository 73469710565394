.syllabus {
  position: relative;
  padding: 71px 16px 77px 16px;
  color: #FDFDFD;
  background-color: #140A52;
}

.syllabus .container {
  gap: 60px;
  z-index: 2;
}

.syllabus .container .img-container {
  display: none;
}

.syllabus .container .description {
  max-width: 646px;
}

.syllabus .container .description .title {
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  margin-bottom: 17px;
}

.syllabus .container .description button {
  margin-top: 40px;
}

.syllabus .bg-bottom-right {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
}

@media screen and (max-width: 700px) {
  .syllabus {
    padding: 40px 30px 40px 30px;
  }

  .syllabus .container {
    display: block;
  }

  .syllabus .container .description .title {
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    margin-top: 20px;
  }

  .syllabus .container .img-container {
    display: block;
    width: 100%;
    text-align: center;
  }

  .syllabus .container .img-container > img {
    width: 208px;
    height: auto;
    text-align: center;
  }

  .syllabus .container .description button {
    width: 100%;
    margin-top: 18px;
  }

  .syllabus .container > img {
    display: none;
  }

  .syllabus .bg-bottom-right {
    display: none;
  }
}