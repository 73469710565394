.blurb {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 90px 30px 80px 30px;
  overflow: hidden;
}

.blurb .container {
  max-width: 748px;
}

.blurb .container .title {
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
}

.blurb .bg-top-left {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -100;
}

@media screen and (max-width: 700px) {
  .blurb {
    padding: 40px 22px 40px 22px;
  }

  .blurb .container .title {
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
  }   

  .blurb .bg-top-left {
    display: none;
  }
}